import React, { useRef, useState, useEffect, useCallback } from "react";
import { createPopper } from "@popperjs/core";

import { throttle, isInTriangle, debounce } from "@modules/helpers";
import { useIsomorphicLayoutEffect } from "@hooks/use-isomorphic-layout-effect";

export function useMouseTracking(element, isHovering) {
    const [isTracking, setIsTracking] = useState(false);
    const [isApproaching, setIsApproaching] = useState(false);
    const startMousePosition = useRef({ x: null, y: null });

    useEffect(() => {
        if (isHovering) {
            setIsTracking(true);
            setIsApproaching(true);
        }
    }, [isHovering]);

    useEffect(() => {
        if (!isHovering && !isApproaching) setIsTracking(false);
    }, [isApproaching, isHovering]);

    useEffect(() => {
        if (!isApproaching) startMousePosition.current = {};
    }, [isApproaching]);

    useEffect(() => {
        function handleMouseLeave() {
            setIsApproaching(false);
        }

        document.addEventListener("mouseleave", handleMouseLeave);
        return () => removeEventListener("mouseleave", handleMouseLeave);
    }, []);

    useEffect(() => {
        if (!isTracking) return;
        if (!element.current) return;

        const elRect = element.current.getBoundingClientRect();

        function handleMouseMove(e) {
            if (!startMousePosition?.current?.x || !startMousePosition?.current?.y) {
                startMousePosition.current = { x: e.clientX, y: e.clientY };
            }

            checkTracking(e.clientX, e.clientY);
        }

        function checkTracking(mouseX, mouseY) {
            if (!startMousePosition.current || !elRect) return false;

            const topLeft = { x: elRect.left, y: elRect.top };
            const topRight = { x: elRect.left + elRect.width, y: elRect.top };
            const bottomLeft = { x: elRect.left, y: elRect.top + elRect.height };
            const bottomRight = { x: elRect.left + elRect.width, y: elRect.top + elRect.height };

            let p1 = { x: startMousePosition.current.x, y: startMousePosition.current.y };
            let p2 = {};
            let p3 = {};

            // Right
            if (p1.x < topLeft.x && p1.x < bottomLeft.x && p1.y > topLeft.y && p1.y < bottomLeft.y) {
                p1 = { x: p1.x - 10, y: p1.y };
                p2 = topLeft;
                p3 = bottomLeft;
            }

            // Top right
            if (p1.x < topLeft.x && p1.x < bottomLeft.x && p1.y > topLeft.y && p1.y > bottomLeft.y) {
                p1 = { x: p1.x - 10, y: p1.y + 10 };

                p2 = topLeft;
                p3 = bottomRight;
            }

            // Top
            if (p1.x > bottomLeft.x && p1.x < bottomRight.x && p1.y > bottomLeft.y && p1.y > bottomRight.y) {
                p1 = { x: p1.x, y: p1.y + 10 };
                p2 = bottomLeft;
                p3 = bottomRight;
            }

            // Top Left
            if (p1.x > bottomLeft.x && p1.x > bottomRight.x && p1.y > bottomLeft.y && p1.y > bottomRight.y) {
                p1 = { x: p1.x + 10, y: p1.y + 10 };
                p2 = bottomLeft;
                p3 = topRight;
            }

            // Left
            if (p1.x > bottomRight.x && p1.x > topRight.x && p1.y > topRight.y && p1.y < bottomRight.y) {
                p1 = { x: p1.x + 10, y: p1.y };
                p2 = bottomRight;
                p3 = topRight;
            }

            // Left Bottom
            if (p1.x > bottomRight.x && p1.x > topRight.x && p1.y < topRight.y && p1.y < bottomRight.y) {
                p1 = { x: p1.x + 10, y: p1.y - 10 };
                p2 = topLeft;
                p3 = bottomRight;
            }

            // Bottom
            if (p1.x > topLeft.x && p1.x < topRight.x && p1.y < topLeft.y && p1.y < topRight.y) {
                p1 = { x: p1.x, y: p1.y - 10 };

                p2 = topLeft;
                p3 = topRight;
            }

            // Bottom Right
            if (p1.x < topLeft.x && p1.x < topRight.x && p1.y < topLeft.y && p1.y < topRight.y) {
                p1 = { x: p1.x - 10, y: p1.y - 10 };
                p2 = bottomLeft;
                p3 = topRight;
            }

            const isIn = isInTriangle(
                mouseX,
                mouseY,
                startMousePosition.current.x,
                startMousePosition.current.y,
                p2.x,
                p2.y,
                p3.x,
                p3.y
            );

            setIsApproaching(isIn);
            return isIn;
        }

        const throttledMouseMove = throttle(handleMouseMove, 500);

        window.addEventListener("mousemove", throttledMouseMove);
        return () => removeEventListener("mousemove", throttledMouseMove);
    }, [element, isTracking]);

    return isApproaching;
}

export function useIsHovering(targetRef, delay, { forwards = true } = {}) {
    const [isHoveringOverTarget, setIsHoveringOverTarget] = useState(false);
    const [keptHovering, setKeptHovering] = useState(false);

    useEffect(() => {
        if (!targetRef.current) return;

        function handleMouseEnter() {
            setIsHoveringOverTarget(true);
        }

        function handleMouseLeave() {
            setIsHoveringOverTarget(false);
        }

        targetRef.current.addEventListener("mouseenter", handleMouseEnter);
        targetRef.current.addEventListener("mouseleave", handleMouseLeave);

        return () => {
            removeEventListener("mouseenter", handleMouseEnter);
            removeEventListener("mouseleave", handleMouseLeave);
        };
    }, [targetRef]);

    useEffect(() => {
        let timer = null;

        if (!delay) setKeptHovering(isHoveringOverTarget);
        else if (!isHoveringOverTarget) {
            if (forwards) setKeptHovering(false);
            else {
                timer = setTimeout(() => {
                    setKeptHovering(false);
                }, 250);
            }
        } else if (isHoveringOverTarget) {
            if (!forwards) setKeptHovering(true);
            else {
                timer = setTimeout(() => {
                    setKeptHovering(true);
                }, 400);
            }
        }

        return () => clearTimeout(timer);
    }, [isHoveringOverTarget]);

    return [isHoveringOverTarget, keptHovering];
}

export function useKeepFocus(dependency) {
    const activeEl = useRef();

    useIsomorphicLayoutEffect(() => {
        if (dependency) {
            activeEl.current = document.activeElement;
        }
    }, [dependency]);

    useEffect(() => {
        if (!dependency && activeEl.current) {
            activeEl.current.focus();
        }
    }, [dependency]);
}

export const usePopper = (options = { modifiers: [] }) => {
    const referenceRef = useRef();
    const popperRef = useRef();
    const arrowRef = useRef();
    const popperInstanceRef = useRef();

    const buildOptions = (options) => ({
        ...options,
        modifiers: [...options.modifiers, { name: "arrow", options: { element: arrowRef.current } }],
    });

    useIsomorphicLayoutEffect(() => {
        const popperInstance = createPopper(referenceRef.current, popperRef.current, buildOptions(options));

        popperInstanceRef.current = popperInstance;

        return () => {
            popperInstance.destroy();
        };
    }, []);

    useIsomorphicLayoutEffect(() => {
        popperInstanceRef.current.setOptions(buildOptions(options));
        popperInstanceRef.current.update();
    }, [options]);

    return {
        reference: referenceRef,
        popper: popperRef,
        arrow: arrowRef,
        update: popperInstanceRef.current?.update,
    };
};

export function useNode() {
    const [node, setNode] = useState(null);
    const ref = useCallback((node) => {
        if (node !== null) {
            setNode(node);
        }
    }, []);
    return [node, ref];
}

export function useClientRect() {
    const [rect, setRect] = useState(null);
    const ref = useCallback((node) => {
        if (node !== null) {
            setRect(node.getBoundingClientRect());
        }
    }, []);
    return [rect, ref];
}

export function useResponsiveRect(ref, measure) {
    const [rect, setRect] = useState();

    useEffect(() => {
        if (!measure && rect) setRect(null);
        if (!measure || !ref.current) return;

        function handleRectUpdate() {
            const rect = ref.current.getBoundingClientRect();

            setRect(rect);
        }

        const throttledResize = throttle(handleRectUpdate, 100);
        const debouncedScroll = debounce(handleRectUpdate, 100);

        window.addEventListener("resize", throttledResize);
        window.addEventListener("scroll", debouncedScroll);

        // This is the initial measurement, the event listeners are only responsible for updating the
        // measurement if something changes from this point onwards.
        handleRectUpdate();

        return () => {
            window.removeEventListener("resize", throttledResize);
            window.removeEventListener("scroll", debouncedScroll);
        };
    }, [measure]);

    return rect;
}

export function useResizeThresholdTrigger(
    elemToWatch,
    resizeThresholds,
    onResizeThresholdsMet,
    resizeThresholdTrigger
) {
    if (onResizeThresholdsMet && (!resizeThresholds || resizeThresholds.length === 0)) {
        throw new Error("useResizeThresholdTrigger: resizeThresholds not supplied");
    }
    const previousThresholdsTriggered = useRef([]);

    useEffect(() => {
        if (!elemToWatch || !onResizeThresholdsMet) return;

        function getThresholdsTriggered(width) {
            return resizeThresholds.filter(({ minWidth, maxWidth }) => {
                if (!minWidth && !maxWidth) throw new Error("Input: Invalid threshold supplied");
                if (!minWidth) return width <= maxWidth;
                if (!maxWidth) return width >= maxWidth;
                else return width >= minWidth && width <= maxWidth;
            });
        }

        // By getting the diff between the previous thresholds triggered and the new thresholds triggered we can
        // tell if any new thresholds where breached and can therefore be alerted
        function getThresholdsTriggeredToAlert(previousThresholds, newThresholds) {
            const allThresholds = [...previousThresholds, ...newThresholds];

            return (
                allThresholds
                    .filter(({ name: n1 }) => {
                        const inPrevious = previousThresholds.find(({ name: n2 }) => n1 === n2);
                        const inCurrent = newThresholds.find(({ name: n2 }) => n1 === n2);

                        // If it's in both than nothing has changed and there is no need to alert
                        return !inPrevious || !inCurrent;
                    })
                    // If it's in the previous and not in the current than its no longer met
                    // If it's in the current and not in the previous than it's now met
                    .map((threshold) => ({
                        ...threshold,
                        state: !!newThresholds.find(({ name: n2 }) => threshold.name === n2),
                    }))
            );
        }

        function onResize(entries) {
            for (let entry of entries) {
                const width = entry.contentBoxSize?.inlineSize || entry.contentRect.width;

                const thresholdsTriggered = getThresholdsTriggered(width);
                const thresholdsTriggeredToAlert = getThresholdsTriggeredToAlert(
                    previousThresholdsTriggered.current,
                    thresholdsTriggered
                );

                // If we set the resizeThresholdTrigger to all then we invoke the callback on every resize where
                // the condition is met.
                const shouldTriggerResize =
                    resizeThresholdTrigger === "all" || thresholdsTriggeredToAlert.length !== 0;

                if (shouldTriggerResize) onResizeThresholdsMet(thresholdsTriggeredToAlert);

                previousThresholdsTriggered.current = thresholdsTriggered;
            }
        }

        function initObserver() {
            const resizeObserver = new ResizeObserver(onResize);

            resizeObserver.observe(elemToWatch);

            return () => resizeObserver.unobserve(elemToWatch);
        }

        if (window.ResizeObserver) {
            return initObserver();
        } else {
            // Allow the user of this hook to supply a default field (or whatever else) to resizeThresholds, and we will
            // invoke their callback once if the resizeobserver doesn't exist so that they can still have a fallback.
            if (onResizeThresholdsMet) onResizeThresholdsMet(resizeThresholds);
        }
    }, [resizeThresholds, onResizeThresholdsMet, resizeThresholdTrigger]);
}

export function useFindDOMNode(ancestorEl, selector, deps = []) {
    const [domNode, setDomNode] = useState();

    useEffect(() => {
        if (!ancestorEl) return;
        const domNode = ancestorEl.querySelector(selector);

        if (domNode) setDomNode(domNode);
    }, [...deps, ancestorEl]);

    return domNode;
}
