import React, { useState, useEffect, forwardRef, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { useForkedRef } from "@reach/utils";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";

import { useResizeThresholdTrigger } from "@hooks/element-hooks";
import { isUrl } from "@modules/string-filters";

import "./Input.scss";

const Input = forwardRef(
    (
        {
            label,
            placeholder = "",
            fixed = false,
            type = "text",
            value = "",
            theme,
            id,
            validators,
            showErrorMessage = false,
            contained = false,
            onChange,
            showErrorOn = "blur",
            onError,
            disabled = false,
            multiLine = false,
            size = "normal",
            errorOverride,
            onResizeThresholdsMet,
            resizeThresholdTrigger = "threshold", // or "always"
            resizeThresholds = [], // { name: "small", minWidth: 20, maxWidth: 620 }
            ...rest
        },
        forwardedRef
    ) => {
        const [isError, setIsError] = useState(false);
        const [errorMessage, setErrorMessage] = useState("");
        const [canSeePassword, setCanSeePassword] = useState(false);
        const [hasFocus, setHasFocus] = useState(false);
        const hasTypedRef = useRef(false);
        const [hasBlurred, setHasBlurred] = useState(false);
        const inputRef = useRef();
        const ref = useForkedRef(inputRef, forwardedRef);

        // I need this so I can determine if we have blurred, i.e we had focus and lost it
        const hasFocusedRef = useRef(false);

        useEffect(() => {
            if (errorOverride) {
                setErrorMessage(errorOverride);
                setIsError(true);
            }
        }, [errorOverride]);

        function validateInput() {
            // No validator edge cases
            if (!validators) return { isValid: true };
            if (Array.isArray(validators) && validators.length === 0) return { isValid: true };

            return validators.reduce(
                (result, validator) => {
                    const { message, priority, validate } = validator;
                    const isValid = validate(value);

                    if (!isValid && priority > (result.priority || 0)) return { message, priority, isValid };
                    else return result;
                },
                { isValid: true }
            );
        }

        useEffect(() => {
            const { isValid, message } = validateInput();

            if (!isValid && !errorOverride) {
                setIsError(true);
                setErrorMessage(message);
            } else if (!errorOverride) {
                setIsError(false);
                setErrorMessage("");
            }
        }, [value]);

        useEffect(() => {
            function handleClick(event) {
                if (!event.target.closest(".scribe-input")) {
                    setHasFocus(false);
                } else setHasFocus(true);
            }

            document.addEventListener("click", handleClick);
            return () => document.removeEventListener("click", handleClick);
        }, []);

        useEffect(() => {
            if (onError) onError(isError, id);
        }, [isError]);

        useEffect(() => {
            if (hasFocus) {
                hasFocusedRef.current = true;
            } else if (hasFocusedRef.current) {
                setHasBlurred(true);
            }
        }, [hasFocus]);

        function handleChange(event) {
            hasTypedRef.current = true;

            onChange(event);
        }

        useResizeThresholdTrigger(
            inputRef.current,
            resizeThresholds,
            onResizeThresholdsMet,
            resizeThresholdTrigger
        );

        function shouldShowError() {
            if (!showErrorMessage) return false;

            if (typeof showErrorMessage === "function")
                return showErrorMessage({
                    value,
                    hasFocus,
                    hasTyped: hasTypedRef.current,
                    hasBlurred: hasBlurred,
                });
            if (typeof showErrorMessage === "boolean") return showErrorMessage;
            else return false;
        }

        const inputType = type !== "password" ? type : canSeePassword ? "text" : "password";
        const showError = shouldShowError();
        const ContainerComponent = label ? "label" : "div";

        return (
            <div
                className="scribe-input"
                data-contained={contained || multiLine}
                data-theme={theme}
                data-size={size}
            >
                <ContainerComponent
                    className="input-container"
                    data-error={showError && isError}
                    data-fixed={fixed}
                >
                    {!multiLine && (
                        <motion.input
                            type={inputType}
                            value={value}
                            onChange={handleChange}
                            data-value={value.length > 0}
                            data-fixed={fixed}
                            //data-errors-enabled={typeof showErrorMessage === "boolean" &&}
                            data-scribe-input
                            ref={ref}
                            id={id}
                            disabled={disabled}
                            {...rest}
                        />
                    )}
                    {multiLine && (
                        <motion.textarea
                            value={value}
                            data-scribe-input
                            onChange={handleChange}
                            data-value={value.length > 0}
                            data-fixed={fixed}
                            ref={ref}
                            id={id}
                            disabled={disabled}
                            {...rest}
                        />
                    )}
                    {!(value.length > 0 && fixed) && (!!label || !!placeholder) && (
                        <span className="label-text">{label || placeholder}</span>
                    )}
                    {type === "password" && (
                        <button
                            className="reveal-password-toggle-container"
                            onClick={() => setCanSeePassword(!canSeePassword)}
                            type="button"
                        >
                            {canSeePassword && <FontAwesomeIcon icon={faEyeSlash} />}
                            {!canSeePassword && <FontAwesomeIcon icon={faEye} />}
                        </button>
                    )}
                    {showError && <span className="error-text">{errorMessage}</span>}
                </ContainerComponent>
            </div>
        );
    }
);

export default Input;

/*
Validators should be able to allow for three different scenarios:
1. Validate the input immediately (before + after any change)
2. Validate the input after an initial change
3. Validate the input on blur
4. Validate the input when an external action occurs (e.g. press the submit button)
*/

const notNullValidator = {
    message: "This field can't be empty",
    priority: 1,
    validate(value) {
        return value && value.trim() !== "";
    },
};

const emailValidator = {
    message: "This email address isn't valid",
    priority: 1,
    validate(value) {
        const re = /^\S+@\S+$/;

        return value && re.test(value.trim()) === true;
    },
};

const urlValidator = {
    message: "This URL is not valid",
    priority: 2,
    validate(value) {
        return isUrl(value.trim());
    },
};

const passwordValidators = [
    {
        message: "Password must contain a symbol (e.g. !, @, %)",
        priority: 1,
        validate(value) {
            // prettier-ignore
            const symbols = ["~","`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "-", "_", "+", "=", "{", "}", "[", "]", "|", "\\", ";", ":", "<", ">", ",", ".", "/", "?"];
            return symbols.some((s) => value.includes(s));
        },
    },
    {
        message: "Password must contain a number",
        priority: 1,
        validate(value) {
            return /\d/.test(value);
        },
    },
    {
        message: "Password has to be 8 or more characters",
        priority: 2,
        validate(value) {
            return value.length > 7;
        },
    },
    {
        message: "Password must contain an uppercase letter",
        priority: 1,
        validate(value) {
            return /[A-Z]/.test(value);
        },
    },
];

export { notNullValidator, emailValidator, passwordValidators, urlValidator };
